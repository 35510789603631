const CryptoJS = require("crypto-js");

class DataEncrypter {
    static encrypt(plainText, secret) {
        var key = CryptoJS.enc.Utf8.parse(secret);
        let iv = CryptoJS.lib.WordArray.create(key.words.slice(0, 4));
      

        var cipherText = CryptoJS.AES.encrypt(plainText, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
          });


      return { 
        cipher: cipherText.toString(), 
        iv: CryptoJS.enc.Base64.stringify(iv)
        };
    }

    static decrypt(cipherText, secret, iv) {

        let iv1 = CryptoJS.enc.Base64.parse(iv);
        
        var key = CryptoJS.enc.Utf8.parse(secret);

        var decrypted = CryptoJS.AES.decrypt(cipherText, key, { iv: iv1 });
  
        return decrypted.toString(CryptoJS.enc.Utf8);
  }

  static padKey(key, length) {
    if (key.length >= length) {
        return key.substring(0, length);
    } else {
       
        while (key.length < length) {
            key += key;
        }
        return key.substring(0, length);
    }
}
  
}

module.exports = DataEncrypter;