<template>
  <div>
    <h1>Encryption App</h1>

    <div>
      <label for="plainText">Text:</label><br>
      <textarea id="plainText" v-model="text" rows="4" cols="50"></textarea><br><br>
      
      <label for="iv">IV:</label><br>
      <textarea id="iv" v-model="iv" rows="2" cols="50"></textarea><br><br>
      
      <label for="secret">Secret Key:</label><br>
      <input type="text" id="secret" v-model="secret" size="50"><br><br>
      
      <button @click="encryptText">Encrypt</button>
      <button @click="decryptText">Decrypt</button>
    </div>

    <h2>Output</h2>
    <div id="output">
      <p v-if="type == 'encrypted'"> Encrypted Json:  {{ output }}</p>
      <p v-if="type == 'decrypted'"> Plain Text: {{ output }}</p>
    </div>
  </div>
</template>

<script>

import DataEncrypter from './data_encrypter'

export default {
  data() {
    return {
      text: '',
      iv: '',
      secret: '',
      output: '',
      type: ''
    };
  },
  methods: {
    encryptText() {
     this.type = 'encrypted';
     this.output = DataEncrypter.encrypt(this.text, this.secret)
    },
    decryptText() {
        this.type = 'decrypted';
        this.output =DataEncrypter.decrypt(this.text, this.secret,this.iv)
    }
  }
};
</script>

<style>
/* Add your CSS styles here if needed */
</style>
